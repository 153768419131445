<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCTION PROCESS</title>
    <section class="content-header">
      <h1>
        Add Production Process
        <br />
        <h4>Please enter add production process.</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Add production process.</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Production Process</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Batch Code</label>
                  <input
                    type="text"
                    v-model="isidata.nomorproduksi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Batch Code"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="isidata.res"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Proses</label>
                  <select
                    class="form-control"
                    v-model="isidata.proses"
                    id="proses"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="proses in process"
                      :key="proses.kode_proses"
                      :value="proses.kode_proses"
                    >
                      {{ proses.nama_proses }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Production Process</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglproses"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Process Date"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Results</label>
                  <input
                    type="number"
                    v-model="isidata.resproses"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Process Results"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="isidata.jumlah"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Buyer Order Data Lists</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by number order "
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Product Number</th>
                      <th>Buyer Code</th>
                      <th>Order Number</th>
                      <th>Order Date</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.kode_buyer }}</td>
                      <td>{{ classdata.order_number }}</td>
                      <td>{{ classdata.tanggal_order }}</td>
                      <td>{{ classdata.qty }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>{{ classdata.tanggal_produksi_start }}</td>
                      <td>{{ classdata.tanggal_produksi_end }}</td>
                      <td>
                        <div class="box-footer">
                          <button
                            @click="saveData(classdata)"
                            type="button"
                            title="Save Production Process"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-save"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      list_paged: [],
      process: [],
      namesearch: "",
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      isidata: {
        nomorproduksi: "",
        res: "",
        proses: "",
        catatan: "",
        tglproses: "",
        resproses: "",
        jumlah: ""
      },
      classdata: {
        id: ""
      }
    };
  },
  created() {
    this.getbuyerorder();
    this.getproses();
    this.isidata.res = sessionStorage.getItem("resource");
  },
  methods: {
    getbuyerorder() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "buyer_order/getalldatabuyer_order?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged = resp.data.data;
          this.count = resp.data.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    getproses() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // console.log(brgtype);
      const urlAPIget = this.$apiurl + "daftar_proses/getdaftar_proses_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          this.process = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    saveData: function(classdata) {
      this.loading = true;
      var nop = this.isidata.nomorproduksi;
      var pros = this.isidata.proses;
      var catat = this.isidata.catatan;
      var hasil = this.isidata.resproses;
      var jml = this.isidata.jumlah;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Batch Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (pros == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (hasil == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Result can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const urlApiSaveDataHeader =
          this.$apiurl + "proses_produksi/saveproses_produksi";
        const urlApiSaveDataWH =
          this.$apiurl +
          "proses_produksi_d_material/saveproses_produksi_d_material";
        const urlApiSaveDataProd =
          this.$apiurl +
          "proses_produksi_d_dari_proses_produksi/saveproses_produksi_d_dari_proses_produksi";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };

        const paramdataheader = {
          nomor_produksi: this.isidata.nomorproduksi,
          kode_proses: this.isidata.proses,
          notes: this.isidata.catatan,
          tanggal_proses: this.isidata.tglproses,
          kode_user: kodeuser,
          hasil_proses: this.isidata.resproses,
          id_buyer_order: classdata.id
        };

        // console.log(paramdataheader);

        if (this.isidata.res == "warehouse") {
          //warehouse
          const paramdatawh = {
            nomor_produksi: this.isidata.nomorproduksi,
            id_outgoing_d: this.$route.params.id,
            notes: this.isidata.catatan,
            qty: this.isidata.jumlah,
            kode_user: kodeuser
          };

          console.log(paramdatawh);
          // const paramdatawh = {
          //   nomor_produksi: this.isidata.nomorproduksi,
          //   kode_proses: this.isidata.proses,
          //   notes: this.isidata.catatan,
          //   tanggal_proses: this.isidata.tglproses,
          //   kode_user: kodeuser,
          //   hasil_proses: this.isidata.resproses,
          //   qty_barang: this.isidata.jumlah,
          //   id_outgoing: this.$route.params.id,
          //   ref_id_proses_produksi: "",
          //   id_buyer_order: classdata.id
          // };
          axios
            .post(urlApiSaveDataHeader, paramdataheader, { headers })
            .then((resp) => {
              if (resp.data.status == true) {
                axios
                  .post(urlApiSaveDataWH, paramdatawh, { headers })
                  .then((resp) => {
                    if (resp.data.status == true) {
                      swal({
                        icon: "success",
                        title: "Success",
                        text: "Save data successfully",
                        showConfirmButton: false
                      });
                      this.loading = false;
                      this.$router.push({ name: "prosesproduksiheader" });
                    } else {
                      swal({
                        icon: "warning",
                        title: "Warning",
                        text: "Save data failed",
                        showConfirmButton: false
                      });
                      this.loading = false;
                      this.$router.push({ name: "prosesproduksiheader" });
                    }
                  })
                  .catch((err) => {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: err.response.data,
                      showConfirmButton: false
                    });
                    console.log(err);
                    this.loading = false;
                  });
                // this.loading = false;
                // this.$router.push({ name: "prosesproduksiheader" });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Save data failed",
                  showConfirmButton: false
                });
                this.loading = false;
                this.$router.push({ name: "prosesproduksiheader" });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false
              });
              console.log(err);
              this.loading = false;
            });
        } else {

          //produksi
          const paramdataprod = {
            nomor_produksi: this.isidata.nomorproduksi,
            id_proses_produksi: this.$route.params.id,
            notes: this.isidata.catatan,
            qty: this.isidata.jumlah,
            kode_user: kodeuser
          };

          console.log(paramdataprod);
          // const paramdataprod = {
          //   nomor_produksi: this.isidata.nomorproduksi,
          //   kode_proses: this.isidata.proses,
          //   notes: this.isidata.catatan,
          //   tanggal_proses: this.isidata.tglproses,
          //   kode_user: kodeuser,
          //   hasil_proses: this.isidata.resproses,
          //   qty_barang: this.isidata.jumlah,
          //   id_outgoing: "",
          //   ref_id_proses_produksi: this.$route.params.id,
          //   id_buyer_order: classdata.id
          // };

          axios
            .post(urlApiSaveDataHeader, paramdataheader, { headers })
            .then((resp) => {
              if (resp.data.status == true) {
                axios
                  .post(urlApiSaveDataProd, paramdataprod, { headers })
                  .then((resp) => {
                    if (resp.data.status == true) {
                      swal({
                        icon: "success",
                        title: "Success",
                        text: "Save data successfully",
                        showConfirmButton: false
                      });
                      this.loading = false;
                      this.$router.push({ name: "prosesproduksiheader" });
                    } else {
                      swal({
                        icon: "warning",
                        title: "Warning",
                        text: "Save data failed",
                        showConfirmButton: false
                      });
                      this.loading = false;
                      this.$router.push({ name: "prosesproduksiheader" });
                    }
                  })
                  .catch((err) => {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: err.response.data,
                      showConfirmButton: false
                    });
                    console.log(err);
                    this.loading = false;
                  });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Save data failed",
                  showConfirmButton: false
                });
                this.loading = false;
                this.$router.push({ name: "prosesproduksiheader" });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    }
  }
};
</script>

<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
